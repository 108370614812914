import React from "react";
import profilePic from "../assets/profile-pic.png";
import { rhythm } from "../utils/typography";

const Bio = () => (
	<div style={{ display: "flex", marginBottom: rhythm(2) }}>
		<img
			src={profilePic}
			alt="Napoleon-Christos Oikonomou"
			style={{
				marginRight: rhythm(1 / 2),
				marginBottom: 0,
				width: rhythm(2),
				height: rhythm(2),
				borderRadius: "50%",
			}}
		/>
		<p style={{ maxWidth: 410 }}>
			{"Secret blog by "}
			<a href="https://iamnapo.me">Napoleon-Christos Oikonomou</a>
			{"."}
			<br />
			{"I’m trying to figure things out."}
		</p>
	</div>
);

export default Bio;
