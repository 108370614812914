import { Link, graphql } from "gatsby";
import React from "react";
import get from "lodash/get";
import { formatPostDate, formatReadingTime } from "../utils/helpers";

import Bio from "../components/Bio";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { rhythm } from "../utils/typography";

const BlogIndexTemplate = (props) => {
	const siteTitle = get(props, "data.site.siteMetadata.title");
	const { pageContext, location } = props;
	const { langKey } = pageContext;

	const posts = get(props, "data.allMarkdownRemark.edges");

	return (
		<Layout location={location} title={siteTitle}>
			<SEO />
			<aside>
				<Bio />
			</aside>
			<main>
				{posts.map(({ node }) => {
					const title = get(node, "frontmatter.title") || node.fields.slug;
					return (
						<article key={node.fields.slug}>
							<header>
								<h3
									style={{
										fontFamily: "Montserrat, sans-serif",
										fontSize: rhythm(1),
										marginBottom: rhythm(1 / 4),
									}}
								>
									<Link
										style={{ boxShadow: "none" }}
										to={node.fields.slug}
										rel="bookmark"
									>
										{title}
									</Link>
								</h3>
								<small>
									{`${formatPostDate(node.frontmatter.date, langKey)} • ${formatReadingTime(node.timeToRead)}`}
								</small>
							</header>
							<p
								style={{ marginBottom: 0 }}
								dangerouslySetInnerHTML={{ __html: node.frontmatter.spoiler }}
							/>
							<p style={{ fontSize: rhythm(0.5) }}>
								<Link style={{ boxShadow: "none" }} to={node.fields.slug} rel="bookmark">
									{"Read more"}
								</Link>
							</p>
						</article>
					);
				})}
			</main>
			<Footer />
		</Layout>
	);
};

export default BlogIndexTemplate;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            langKey
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
          }
        }
      }
    }
  }
`;
